<template>
    <section class="banner-block" v-bind:[attributes.componentId]="type.id">
        <div class="container" :class="paddingOptions(selectedPaddingOptions)"
            v-bind:[attributes.elementCodename]="type.elements.content.codename">
            <div class="row">
                <div class="col">
                    <div v-if="shouldRenderIcon()">
                        <div class="icon-text-container">
                            <div class="icon">
                                <NuxtImg :src="iconUrl" :alt="iconName" class="img-fluid"
                                    v-bind:[attributes.elementCodename]="type.elements.icon.codename" />
                            </div>
                            <h4 class="icon-text">
                                {{ iconText }}
                            </h4>
                        </div>
                    </div>
                    <h3 class="content">
                        <RichText :value="content" />
                    </h3>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { contentTypes, type BannerModel } from '~/models';
import { paddingOptions } from '#imports';
const props = defineProps<{ model: BannerModel }>();
const content = ref();
const iconText = ref('');
const iconUrl = ref('');
const iconName = ref('');

content.value = props.model.elements.content.value;
iconText.value = props.model.elements.iconText.value;
iconName.value = props.model.elements.icon.name;
iconUrl.value = props.model.elements.icon?.value?.[0]?.url;

const shouldRenderIcon = () => {
    return iconText.value?.length > 0 && iconUrl.value?.length > 0;
};

const attributes = GetKontentAttributes();
const type = contentTypes._banner;
const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

</script>

<style lang="scss" scoped></style>