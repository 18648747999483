<template>
    <section class="image-block" v-bind:[attributes.componentId]="type.id" v-bind:[attributes.addButton]="true">

        <div class="background-light" v-if="getStyleEnumByModel(model) == StyleEnum.LightBackground">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col">
                        <div class="primary-img" v-bind:[attributes.elementCodename]="type.elements.primary_image.codename">
                            <NuxtImg provider="kontentAiProvider" :src="primaryImageUrl" class="img-fluid" alt="..." />
                        </div>
                        <div class="mobile-img" v-bind:[attributes.elementCodename]="type.elements.mobile_images.codename">
                            <MobileImageBlock :model="model" carousel-id="carousellight" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="background-dark" v-if="getStyleEnumByModel(model) == StyleEnum.DarkBackground">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col">
                        <div class="primary-img" v-bind:[attributes.elementCodename]="type.elements.primary_image.codename">
                            <NuxtImg provider="kontentAiProvider" :src="primaryImageUrl" class="img-fluid" alt="..." />
                        </div>
                        <div class="mobile-img" v-bind:[attributes.elementCodename]="type.elements.mobile_images.codename">
                            <MobileImageBlock :model="model" carousel-id="carouseldark" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="background-img" :style="backgroundImageStyle"
            v-if="getStyleEnumByModel(model) == StyleEnum.ImageBackground && model?.elements?.backgroundImage?.value[0]?.url">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col">
                        <div class="primary-img" v-bind:[attributes.elementCodename]="type.elements.primary_image.codename">
                            <NuxtImg provider="kontentAiProvider" :src="primaryImageUrl" class="img-fluid" alt="..." />
                        </div>
                        <div class="mobile-img" v-bind:[attributes.elementCodename]="type.elements.mobile_images.codename">
                            <MobileImageBlock :model="model" carousel-id="carouselimg" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { contentTypes, type ImageBlockModel } from '~/models';
import { paddingOptions } from '#imports';

//#region Style
enum StyleEnum {
    None,
    ImageBackground,
    LightBackground,
    DarkBackground,
}

const styleMap = new Map<string, StyleEnum>([
    ['(None)', StyleEnum.None],
    ['Image Background', StyleEnum.ImageBackground],
    ['Light Background', StyleEnum.LightBackground],
    ['Dark Background', StyleEnum.DarkBackground],
]);

function getStyleEnumByModel(model: ImageBlockModel | null): StyleEnum {

    if (!model) return StyleEnum.None;
    if (model.elements.imageStyle && model.elements.imageStyle.value && model.elements.imageStyle.value.length > 0) {

        let style = model.elements.imageStyle.value[0].name;
        return styleMap.get(style) ?? StyleEnum.None;

    }

    return StyleEnum.None;

}

//#endregion

//#region attributes
const attributes = GetKontentAttributes();
const type = contentTypes._image_block;
//#endregion

const props = defineProps<{ model: ImageBlockModel }>();

const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

// Initialize a ref for the image URL
const imageUrl = ref('');
const primaryImageUrl = ref('');

if (props.model?.elements?.primaryImage?.value[0]?.url) {
    primaryImageUrl.value = props.model?.elements?.primaryImage?.value[0]?.url
}

onMounted(() => {
    const styleEnum = getStyleEnumByModel(props.model);

    if (styleEnum === StyleEnum.ImageBackground) {
        const desktopUrl = props.model.elements.backgroundImage?.value[0]?.url;
        const mobileUrl = props.model.elements.mobileBackgroundImage?.value[0]?.url || desktopUrl;

        // Check screen width and set imageUrl
        imageUrl.value = window.matchMedia('(max-width: 575px)').matches ? mobileUrl : desktopUrl;
    }
});

// Use imageUrl in a computed property to reactively update the style
const backgroundImageStyle = computed(() => {
    return imageUrl.value ? { 'background-image': `url(${imageUrl.value})` } : {};
});

</script>

<style lang="scss" scoped></style>