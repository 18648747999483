/**
 * Generated by '@kontent-ai/model-generator@6.5.0'
 */
export const contentTypes = {
    /**
     *    Navigation Item
     */
    navigation_item: {
        codename: 'navigation_item',
        id: '37b7bab7-3104-47ee-b487-d78c90bc8013',
        externalId: undefined,
        name: '🌎 Navigation Item',
        elements: {
            /**
             * External URL (text)
             */
            external_url: {
                codename: 'external_url',
                id: '8758778b-f334-4a44-a773-15005c3d289a',
                externalId: undefined,
                name: 'External URL',
                required: false,
                type: 'text'
            },

            /**
             * Page (modular_content)
             */
            page: {
                codename: 'page',
                id: '965ff819-8a17-424e-9045-c5125fc54e07',
                externalId: undefined,
                name: 'Page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '37faa393-ce09-49d9-ad4a-568290162d59',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Navigation Section
     */
    _navigation_section: {
        codename: '_navigation_section',
        id: 'ed2db0c4-da97-436a-8686-1a332b025901',
        externalId: undefined,
        name: '🌎 Navigation Section',
        elements: {
            /**
             * Navigation Items (modular_content)
             */
            navigation_items: {
                codename: 'navigation_items',
                id: 'c3af3f49-2f71-4fde-b12e-92cbe86480d8',
                externalId: undefined,
                name: 'Navigation Items',
                required: false,
                type: 'modular_content'
            }
        }
    },

    /**
     *    Script Tag
     */
    _script_tag: {
        codename: '_script_tag',
        id: 'ace887f8-0132-42d2-bd8f-ba0f25842315',
        externalId: undefined,
        name: '🌎 Script Tag',
        elements: {
            /**
             * Inner Code (text)
             */
            inner_code: {
                codename: 'inner_code',
                id: '829b1468-1e59-4e0b-9467-0cebb903ee6c',
                externalId: undefined,
                name: 'Inner Code',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Site Configuration
     */
    site_configuration: {
        codename: 'site_configuration',
        id: 'b1b6e981-f20b-4939-b989-36fb02724489',
        externalId: undefined,
        name: '🌎 Site Configuration',
        elements: {
            /**
             * Copyright Text (text)
             *
             * This is the text that comes after "Copyright 2024 ©"
             */
            legal_text: {
                codename: 'legal_text',
                id: '20b1e0a0-f4d6-4dcc-a553-d61748df9b00',
                externalId: undefined,
                name: 'Copyright Text',
                required: false,
                type: 'text'
            },

            /**
             * Favicon (asset)
             */
            favicon: {
                codename: 'favicon',
                id: '0575d675-45d8-4133-9fb2-05e760025a7e',
                externalId: undefined,
                name: 'Favicon',
                required: false,
                type: 'asset'
            },

            /**
             * Navigation Footer (modular_content)
             */
            navigation_footer: {
                codename: 'navigation_footer',
                id: 'd77e8c80-2ba1-48c2-9a24-1242ead86843',
                externalId: undefined,
                name: 'Navigation Footer',
                required: false,
                type: 'modular_content'
            },

            /**
             * Navigation Header (modular_content)
             */
            navigation_header: {
                codename: 'navigation_header',
                id: 'fe2baf24-efac-4b4f-8823-a6a428906b8c',
                externalId: undefined,
                name: 'Navigation Header',
                required: false,
                type: 'modular_content'
            },

            /**
             * Open Text Area (rich_text)
             *
             * This is a text area that will display above the copyright text
             */
            open_text_area: {
                codename: 'open_text_area',
                id: 'd549fdae-514a-43d6-8d4b-71a516a10bc7',
                externalId: undefined,
                name: 'Open Text Area',
                required: false,
                type: 'rich_text'
            },

            /**
             * Script Tags (modular_content)
             */
            script_tags: {
                codename: 'script_tags',
                id: '30ab2ea6-8595-490d-8c04-cb48eb011fbd',
                externalId: undefined,
                name: 'Script Tags',
                required: false,
                type: 'modular_content'
            },

            /**
             * Site Name (text)
             */
            site_name: {
                codename: 'site_name',
                id: '47c89324-7d60-44cc-b1f2-d182ba59ec05',
                externalId: undefined,
                name: 'Site Name',
                required: false,
                type: 'text'
            },

            /**
             * TrustArc Code Block (text)
             */
            trustarc_code_block: {
                codename: 'trustarc_code_block',
                id: 'c2a0f6c6-dd80-4334-b5c0-3018c0d9fcc4',
                externalId: undefined,
                name: 'TrustArc Code Block',
                required: false,
                type: 'text'
            },

            /**
             * Website Logo (asset)
             */
            website_logo: {
                codename: 'website_logo',
                id: '44fa3fb2-51fe-4d38-8db0-2329c0fc9ff7',
                externalId: undefined,
                name: 'Website Logo',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Homepage
     */
    homepage: {
        codename: 'homepage',
        id: '801401ac-324c-4406-910b-230a632daa42',
        externalId: undefined,
        name: '📄 Homepage',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '43e41f26-8558-4361-9a5c-8a2f9693452e',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: '3252f1b5-0a3f-4085-84d8-b43b05f3ad41',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Description (text)
             */
            seo_metadata__meta_description: {
                codename: 'seo_metadata__meta_description',
                id: 'af1d796d-939b-406c-8148-a1b8c4a9abaf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '46026da7-dcac-4418-ad71-db8732553124',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '7f9cb962-4e24-4090-a768-bf3d0caff4da',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '48d58f93-ec74-48ef-a74a-51e7229ce950',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: '9fc99df0-be38-4f0c-a118-ec9a6d4e194c',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *    Interior Page
     */
    page: {
        codename: 'page',
        id: 'ac8dbf79-6d86-4bf9-ab04-f97ae905b6f8',
        externalId: undefined,
        name: '📄 Interior Page',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '581b82c2-2163-4ad2-8dad-b292238fd0ed',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Hero (modular_content)
             */
            hero: {
                codename: 'hero',
                id: '7109840b-3123-4b28-8ee6-1b850626fad1',
                externalId: undefined,
                name: 'Hero',
                required: false,
                type: 'modular_content'
            },

            /**
             * Meta Description (text)
             */
            seo_metadata__meta_description: {
                codename: 'seo_metadata__meta_description',
                id: 'af1d796d-939b-406c-8148-a1b8c4a9abaf',
                externalId: undefined,
                name: 'Meta Description',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Meta Keywords (text)
             */
            seo_metadata__meta_keywords: {
                codename: 'seo_metadata__meta_keywords',
                id: '46026da7-dcac-4418-ad71-db8732553124',
                externalId: undefined,
                name: 'Meta Keywords',
                required: false,
                type: 'text',

                snippetCodename: 'seo_metadata'
            },

            /**
             * Subpages (subpages)
             */
            subpages: {
                codename: 'subpages',
                id: '28d559d8-52ab-49da-a2bd-8262f2938c94',
                externalId: undefined,
                name: 'Subpages',
                required: false,
                type: 'subpages'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '2e1dd47b-b19f-4bc4-ab0a-361db8019b7f',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * URL Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'e4c0cc95-9765-4149-a466-659d951a4f8a',
                externalId: undefined,
                name: 'URL Slug',
                required: false,
                type: 'url_slug'
            }
        }
    },

    /**
     *   Anchor Tag
     */
    anchor_tag: {
        codename: 'anchor_tag',
        id: '537cf974-e828-4646-84fd-edd76c753161',
        externalId: undefined,
        name: '🔗Anchor Tag',
        elements: {
            /**
             * ID (text)
             */
            id: {
                codename: 'id',
                id: 'ccea7b2f-64ab-401b-81e2-04226692bfd5',
                externalId: undefined,
                name: 'ID',
                required: false,
                type: 'text'
            },

            /**
             * Show Tag Icon (multiple_choice)
             */
            show_tag_icon: {
                codename: 'show_tag_icon',
                id: 'f8a5e7a0-455c-48d1-8135-c3d39ad0073e',
                externalId: undefined,
                name: 'Show Tag Icon',
                required: false,
                type: 'multiple_choice',
                options: {
                    yes: {
                        name: 'Yes',
                        id: 'c4ced9a2-1d12-43e5-b4d1-ba18b42b60fe',
                        codename: 'yes',
                        externalId: undefined
                    },
                    no: {
                        name: 'No',
                        id: 'ac4e10a0-8e4d-4fc9-9ef6-0745d98c4ef6',
                        codename: 'no',
                        externalId: undefined
                    }
                }
            }
        }
    },

    /**
     *    Accordion Item
     */
    _accordion_item: {
        codename: '_accordion_item',
        id: '45ddd82a-dfe5-4f71-8336-f90064432ee4',
        externalId: undefined,
        name: '🔠 Accordion Item',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '6241d8d2-8258-4833-a0b1-f6a1e225475c',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '8bbd561c-f0f3-4ac2-ae94-a28b490f5ce8',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Accordion List
     */
    _accordion_list: {
        codename: '_accordion_list',
        id: 'fe31e67a-8979-448c-b809-5c89e1a9a4fd',
        externalId: undefined,
        name: '🔠 Accordion List',
        elements: {
            /**
             * Accordion Items (modular_content)
             */
            accordion_items: {
                codename: 'accordion_items',
                id: '49747721-d6dc-436e-bbdb-c4360aac659d',
                externalId: undefined,
                name: 'Accordion Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'c0c340e6-9cb6-4932-bd46-9a3378150b2c',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Call to Action
     */
    _call_to_action: {
        codename: '_call_to_action',
        id: '994b472f-6548-4b15-aa07-57ed13164b88',
        externalId: undefined,
        name: '🔠 Call to Action',
        elements: {
            /**
             * Email Address (text)
             */
            email_address: {
                codename: 'email_address',
                id: 'bac81a68-ac7e-4d00-876f-da6d0e75adaa',
                externalId: undefined,
                name: 'Email Address',
                required: false,
                type: 'text'
            },

            /**
             * Generic Asset Target (asset)
             */
            generic_asset_target: {
                codename: 'generic_asset_target',
                id: 'b46290b0-5b8f-4d49-b5f4-ac9475872fa7',
                externalId: undefined,
                name: 'Generic Asset Target',
                required: false,
                type: 'asset'
            },

            /**
             * Link Title (text)
             */
            link_title: {
                codename: 'link_title',
                id: 'a8dca219-d1af-4ad7-82b7-3a2c635d5ff3',
                externalId: undefined,
                name: 'Link Title',
                required: false,
                type: 'text'
            },

            /**
             * Linked Target (modular_content)
             */
            linked_target: {
                codename: 'linked_target',
                id: '7fdcc94b-a4c7-4fa0-ae51-f71c9ad37e3f',
                externalId: undefined,
                name: 'Linked Target',
                required: false,
                type: 'modular_content'
            },

            /**
             * Manual Target (text)
             */
            manual_target: {
                codename: 'manual_target',
                id: 'c9e917ef-9bc8-4ea7-9a4e-3d541323075b',
                externalId: undefined,
                name: 'Manual Target',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Card Item
     */
    _card_item: {
        codename: '_card_item',
        id: '5101ad5e-f3da-4513-894e-530f387c9fd9',
        externalId: undefined,
        name: '🔠 Card Item',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '5b0a97f2-750c-4566-87d3-a1dd9868f17b',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Icon (asset)
             */
            icon: {
                codename: 'icon',
                id: '2788d44f-3181-4130-84e1-52de51a481ee',
                externalId: undefined,
                name: 'Icon',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '76b4997e-d87f-45a0-8e69-c3259016a047',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Cohort Item
     */
    _cohort_item_2: {
        codename: '_cohort_item_2',
        id: '92397db9-0895-4b43-852f-f65686dbe9ec',
        externalId: undefined,
        name: '🔠 Cohort Item',
        elements: {
            /**
             * Card Title   Line 1 (text)
             */
            card_title___line_1: {
                codename: 'card_title___line_1',
                id: '23342439-b017-4e16-8e1e-7b9679838638',
                externalId: undefined,
                name: 'Card Title - Line 1',
                required: false,
                type: 'text'
            },

            /**
             * Card Title   Line 2 (text)
             */
            card_title___line_2: {
                codename: 'card_title___line_2',
                id: 'c5524f57-5b97-48c9-b75c-a8e9fd954f79',
                externalId: undefined,
                name: 'Card Title - Line 2',
                required: false,
                type: 'text'
            },

            /**
             * Cohort type (text)
             */
            cohort_type: {
                codename: 'cohort_type',
                id: '228c3e8e-5812-414f-9ce4-5218a87bfd44',
                externalId: undefined,
                name: 'Cohort type',
                required: false,
                type: 'text'
            },

            /**
             * Enrollment dates (text)
             */
            enrollment_dates: {
                codename: 'enrollment_dates',
                id: '2cd4b6cf-8eee-4e86-adc9-1f1ec730a758',
                externalId: undefined,
                name: 'Enrollment dates',
                required: false,
                type: 'text'
            },

            /**
             * Funding sources (rich_text)
             */
            funding_sources: {
                codename: 'funding_sources',
                id: '33188548-78b7-4521-b7f3-4d6e3779d0c6',
                externalId: undefined,
                name: 'Funding sources',
                required: false,
                type: 'rich_text'
            },

            /**
             * Location (text)
             */
            location: {
                codename: 'location',
                id: '8b3a7c71-dfc9-400e-a38b-391476f4f7c9',
                externalId: undefined,
                name: 'Location',
                required: false,
                type: 'text'
            },

            /**
             * Logo (asset)
             */
            logo: {
                codename: 'logo',
                id: '959ccc75-c21c-4763-a18a-10e0833f4dbf',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Partnerships   participating institutions (rich_text)
             */
            partnerships___participating_institutions: {
                codename: 'partnerships___participating_institutions',
                id: '9ae1c682-e993-42fc-bb5c-fbbeaa21131c',
                externalId: undefined,
                name: 'Partnerships & participating institutions',
                required: false,
                type: 'rich_text'
            },

            /**
             * Publications (rich_text)
             */
            publications: {
                codename: 'publications',
                id: 'e741941d-db8d-48fc-a764-338764fe55e8',
                externalId: undefined,
                name: 'Publications',
                required: false,
                type: 'rich_text'
            },

            /**
             * Research focus (text)
             */
            research_focus: {
                codename: 'research_focus',
                id: '99070ed3-cb0c-4101-a699-32b60a4b542e',
                externalId: undefined,
                name: 'Research focus',
                required: false,
                type: 'text'
            },

            /**
             * Study Name (text)
             */
            title: {
                codename: 'title',
                id: 'fbfe64e9-afff-43ac-adfd-8e72427ac681',
                externalId: undefined,
                name: 'Study Name',
                required: false,
                type: 'text'
            },

            /**
             * Study Type (text)
             */
            study_type: {
                codename: 'study_type',
                id: '176b6e37-e964-46d6-8318-b5a2f80866c1',
                externalId: undefined,
                name: 'Study Type',
                required: false,
                type: 'text'
            },

            /**
             * Url Slug (url_slug)
             */
            url_slug: {
                codename: 'url_slug',
                id: 'd1bb74f4-6dc9-4604-bbbc-fa14935c8f99',
                externalId: undefined,
                name: 'Url Slug',
                required: true,
                type: 'url_slug'
            },

            /**
             * Website (rich_text)
             */
            website: {
                codename: 'website',
                id: 'd08a9d64-f215-433d-8f2f-ba72e84d1ae8',
                externalId: undefined,
                name: 'Website',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Horizontal Rule
     */
    _horizontal_rule: {
        codename: '_horizontal_rule',
        id: '07f00c25-7c4e-4924-a19a-0fba4f3aebf2',
        externalId: undefined,
        name: '🔠 Horizontal Rule',
        elements: {}
    },

    /**
     *    Logo Item
     */
    _cohort_item: {
        codename: '_cohort_item',
        id: '15673aac-5d68-491d-a96e-d363c0df49e8',
        externalId: undefined,
        name: '🔠 Logo Item',
        elements: {
            /**
             * Cohort Page (modular_content)
             */
            cohort_page: {
                codename: 'cohort_page',
                id: '01396483-0978-4cd8-8d95-92d984f6baa3',
                externalId: undefined,
                name: 'Cohort Page',
                required: false,
                type: 'modular_content'
            },

            /**
             * Link (text)
             *
             * This will be used for external links.
             */
            link: {
                codename: 'link',
                id: '91e83776-c70b-4c84-b407-211092a2d51f',
                externalId: undefined,
                name: 'Link',
                required: false,
                type: 'text'
            },

            /**
             * Logo (asset)
             */
            image_greyscale: {
                codename: 'image_greyscale',
                id: '565587cd-2ef1-45e8-8fc8-f5f44a02628c',
                externalId: undefined,
                name: 'Logo',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'f7e4ddc0-747d-4568-9d17-e25d5dfec70a',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Publication Item
     */
    _publication_item: {
        codename: '_publication_item',
        id: '66290569-66de-40a0-a5a6-40c114e579c5',
        externalId: undefined,
        name: '🔠 Publication Item',
        elements: {
            /**
             * Leading Author (text)
             */
            author_information: {
                codename: 'author_information',
                id: 'd96d16dc-5ac3-406a-9107-93cc526de34f',
                externalId: undefined,
                name: 'Leading Author',
                required: false,
                type: 'text'
            },

            /**
             * Link to Publication (text)
             */
            link_to_publication: {
                codename: 'link_to_publication',
                id: 'fe4cd9cd-a802-4662-9253-aa5fe21dc739',
                externalId: undefined,
                name: 'Link to Publication',
                required: false,
                type: 'text'
            },

            /**
             * Organization (text)
             */
            organization: {
                codename: 'organization',
                id: 'e4916c9b-7271-445f-8fc0-d0a0fa0bb523',
                externalId: undefined,
                name: 'Organization',
                required: false,
                type: 'text'
            },

            /**
             * Publication Date (date_time)
             */
            publication_date: {
                codename: 'publication_date',
                id: '7dfd31d8-4265-42ac-bcf0-f40838d64d1a',
                externalId: undefined,
                name: 'Publication Date',
                required: false,
                type: 'date_time'
            },

            /**
             * Publication Summary (text)
             */
            publication_summary: {
                codename: 'publication_summary',
                id: 'c4aed8c8-f0e3-45e5-ae66-5c09bf3f805f',
                externalId: undefined,
                name: 'Publication Summary',
                required: false,
                type: 'text'
            },

            /**
             * Publication Title (text)
             */
            publication_title: {
                codename: 'publication_title',
                id: 'd91aed30-6cd0-45ac-b251-c7fdb0ff8acd',
                externalId: undefined,
                name: 'Publication Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Banner
     */
    _banner: {
        codename: '_banner',
        id: '3e79f882-0c5b-4626-86e7-dddf2d257b3b',
        externalId: undefined,
        name: '🧩 Banner',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '0d51f0c7-3a67-4731-bd17-1548e04b0729',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Icon (asset)
             */
            icon: {
                codename: 'icon',
                id: 'fae5532a-c6f0-4c93-9ce3-1da994db2954',
                externalId: undefined,
                name: 'Icon',
                required: false,
                type: 'asset'
            },

            /**
             * Icon Text (text)
             */
            icon_text: {
                codename: 'icon_text',
                id: 'f63eddd6-0ffb-4991-ac0c-0174a7c1be0f',
                externalId: undefined,
                name: 'Icon Text',
                required: false,
                type: 'text'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            }
        }
    },

    /**
     *    Basic Content Block
     */
    _basic_content_block: {
        codename: '_basic_content_block',
        id: 'c368daaa-64e1-4f9d-a798-c0c3d3f257d1',
        externalId: undefined,
        name: '🧩 Basic Content Block',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'ddc6002f-8995-4ee6-849b-822d7a351f2f',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '70ab86fc-6b25-4ed8-922f-365498d1c517',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Basic Content Block  Split Content
     */
    basic_content_block_split_content: {
        codename: 'basic_content_block_split_content',
        id: '6b550d00-6b83-40b0-9a2c-a937fde0cd34',
        externalId: undefined,
        name: '🧩 Basic Content Block (Split Content)',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '4a25da3a-09db-482f-acab-02360efcdb71',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            }
        }
    },

    /**
     *    Card List
     */
    _card_list: {
        codename: '_card_list',
        id: '1a1ee4a1-e70e-4d3a-93a2-3e1fb4c823b7',
        externalId: undefined,
        name: '🧩 Card List',
        elements: {
            /**
             * Card Items (modular_content)
             */
            card_items: {
                codename: 'card_items',
                id: 'bd71f5ed-b062-4416-acd5-3aa35e61912e',
                externalId: undefined,
                name: 'Card Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '3cedef7b-b58a-4764-8d25-6d46baead5cf',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Styling Options (multiple_choice)
             */
            styling_options: {
                codename: 'styling_options',
                id: '18e983d1-7487-401a-8620-3f388e3788fa',
                externalId: undefined,
                name: 'Styling Options',
                required: true,
                type: 'multiple_choice',
                options: {
                    no_icon: {
                        name: 'No Icon',
                        id: 'bdd09fd0-204e-4470-9352-f64bd3dc64ee',
                        codename: 'no_icon',
                        externalId: undefined
                    },
                    left_icon: {
                        name: 'Left Icon',
                        id: 'c11fbf97-9ec4-4b46-8b2b-81e15a1c4dce',
                        codename: 'left_icon',
                        externalId: undefined
                    },
                    top_icon: {
                        name: 'Top Icon',
                        id: '62f80653-0d26-4054-95fc-a73b5f3dd3ad',
                        codename: 'top_icon',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'aa6f9a02-ef24-404b-8521-bdd606f375eb',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Cohort List
     */
    _cohort_list: {
        codename: '_cohort_list',
        id: '5f1be1c0-3b79-4f4b-9582-3291a829ff7e',
        externalId: undefined,
        name: '🧩 Cohort List',
        elements: {
            /**
             * Cohorts (modular_content)
             */
            cohorts: {
                codename: 'cohorts',
                id: '5ad4a465-3c6f-4fb0-ae0f-008d3cfd20d7',
                externalId: undefined,
                name: 'Cohorts',
                required: false,
                type: 'modular_content'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '9769dd6d-0064-4cfe-8347-2ca6a6b3babb',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Contact Block
     */
    _contact_block: {
        codename: '_contact_block',
        id: 'f7f3bc46-4019-407d-b8c5-65adcac84a56',
        externalId: undefined,
        name: '🧩 Contact Block',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: 'c7e9ed1b-3a2e-471c-b078-8d7f321fa1b5',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Image (asset)
             */
            image: {
                codename: 'image',
                id: '9e920771-dc3f-416e-809b-8e42484864d4',
                externalId: undefined,
                name: 'Image',
                required: false,
                type: 'asset'
            },

            /**
             * Mobile Image (asset)
             */
            mobile_image: {
                codename: 'mobile_image',
                id: '468e0dff-6e7b-49de-ab53-0f3b1b06a918',
                externalId: undefined,
                name: 'Mobile Image',
                required: false,
                type: 'asset'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'a4d7aa8e-7d66-45b0-acaf-3d09fb59b34c',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Featured Content Block
     */
    _featured_content_block: {
        codename: '_featured_content_block',
        id: '96e0fbef-01d8-4b94-87d0-5391ce102fd6',
        externalId: undefined,
        name: '🧩 Featured Content Block',
        elements: {
            /**
             * Left Content (rich_text)
             */
            left_content: {
                codename: 'left_content',
                id: '6395c0c1-ea69-4669-96e3-55b7989c90fb',
                externalId: undefined,
                name: 'Left Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Right Content (rich_text)
             */
            right_content: {
                codename: 'right_content',
                id: '1a79d0f6-a535-41cc-a924-6636cc79f701',
                externalId: undefined,
                name: 'Right Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Styling Option (multiple_choice)
             */
            styling_option: {
                codename: 'styling_option',
                id: '80031f3f-aabe-42bd-9985-a21fa13e9073',
                externalId: undefined,
                name: 'Styling Option',
                required: false,
                type: 'multiple_choice',
                options: {
                    navy: {
                        name: 'Navy',
                        id: '6321658e-1106-4d99-8b51-f4c67dd7a2b4',
                        codename: 'navy',
                        externalId: undefined
                    },
                    white: {
                        name: 'White',
                        id: 'cf51f8c6-f9b5-450e-bf36-ca2c0bafb004',
                        codename: 'white',
                        externalId: undefined
                    }
                }
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'd7314384-55b8-4a22-94f9-5c9d49791104',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Homepage Hero
     */
    _homepage_hero: {
        codename: '_homepage_hero',
        id: '4887d0e6-18b7-4008-a8c2-9a134e3d8e95',
        externalId: undefined,
        name: '🧩 Homepage Hero',
        elements: {
            /**
             * Mobile Image (asset)
             */
            mobile_image: {
                codename: 'mobile_image',
                id: '85efcb8f-24ba-4d1a-b1b4-906d482dbfeb',
                externalId: undefined,
                name: 'Mobile Image',
                required: false,
                type: 'asset'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: '8a366349-688b-4138-a651-85c1e01dfc9f',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '724ee0bc-b2e2-448c-b708-f57f76e4c310',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            },

            /**
             * Video (asset)
             */
            video: {
                codename: 'video',
                id: 'e8a84bef-20c0-480c-b21b-a848c0373047',
                externalId: undefined,
                name: 'Video',
                required: false,
                type: 'asset'
            },

            /**
             * Video Overlay (asset)
             */
            video_overlay: {
                codename: 'video_overlay',
                id: '0cad9195-4cb7-465a-a424-ceeb14535417',
                externalId: undefined,
                name: 'Video Overlay',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Image Block
     */
    _image_block: {
        codename: '_image_block',
        id: 'b839ed53-8e09-4fb1-81c8-867837c5b300',
        externalId: undefined,
        name: '🧩 Image Block',
        elements: {
            /**
             * Background Image (asset)
             */
            background_image: {
                codename: 'background_image',
                id: '87565818-b30a-43b7-a233-1b4741db34fe',
                externalId: undefined,
                name: 'Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Image Style (multiple_choice)
             */
            image_style: {
                codename: 'image_style',
                id: '237e67df-8333-4f9f-8684-47ef3f1d9116',
                externalId: undefined,
                name: 'Image Style',
                required: true,
                type: 'multiple_choice',
                options: {
                    image_background: {
                        name: 'Image Background',
                        id: 'f8d28d24-5e7c-48b8-a47a-b9fd1bf32baa',
                        codename: 'image_background',
                        externalId: undefined
                    },
                    light_background: {
                        name: 'Light Background',
                        id: 'ae7407e8-c8d5-48ec-89ea-a51a8d6369e1',
                        codename: 'light_background',
                        externalId: undefined
                    },
                    dark_background: {
                        name: 'Dark Background',
                        id: '9a6a580a-5a34-44ba-81b4-b935252fcfc4',
                        codename: 'dark_background',
                        externalId: undefined
                    }
                }
            },

            /**
             * Mobile Background Image (asset)
             */
            mobile_background_image: {
                codename: 'mobile_background_image',
                id: '4ac54b79-0eb4-4b49-8015-0f82cdad755b',
                externalId: undefined,
                name: 'Mobile Background Image',
                required: false,
                type: 'asset'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Primary Image (asset)
             */
            primary_image: {
                codename: 'primary_image',
                id: 'b56b90d2-f08e-456e-9807-6873736330b2',
                externalId: undefined,
                name: 'Primary Image',
                required: false,
                type: 'asset'
            },

            /**
             * Primary Mobile Image s  (asset)
             *
             * This can be more than one image. If you select more than one image it will show as a slider.
             */
            mobile_images: {
                codename: 'mobile_images',
                id: '5b3c9872-dc57-4007-b2fd-eec988f2d999',
                externalId: undefined,
                name: 'Primary Mobile Image(s)',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Image Block  Full Width
     */
    _image_block__copy_: {
        codename: '_image_block__copy_',
        id: '395bd80c-f05f-413a-be1f-88047341909f',
        externalId: undefined,
        name: '🧩 Image Block (Full Width)',
        elements: {
            /**
             * Image Style (multiple_choice)
             */
            image_style: {
                codename: 'image_style',
                id: '5d218ce5-2498-4d49-9a52-a60d9b539000',
                externalId: undefined,
                name: 'Image Style',
                required: true,
                type: 'multiple_choice',
                options: {
                    image_background: {
                        name: 'Image Background',
                        id: 'ebd17adc-4123-43ad-9bf4-cda1a8c4f439',
                        codename: 'image_background',
                        externalId: undefined
                    },
                    light_background: {
                        name: 'Light Background',
                        id: '2d44e6d2-4de4-4d3c-9892-31e70a37f31d',
                        codename: 'light_background',
                        externalId: undefined
                    },
                    dark_background: {
                        name: 'Dark Background',
                        id: 'd9329a42-cb6a-4c73-acf2-1521b3c7822c',
                        codename: 'dark_background',
                        externalId: undefined
                    }
                }
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Primary Image (asset)
             */
            primary_image: {
                codename: 'primary_image',
                id: 'c147b291-b8b7-4b7b-a690-8ec0e9ced868',
                externalId: undefined,
                name: 'Primary Image',
                required: false,
                type: 'asset'
            },

            /**
             * Secondary Image (asset)
             */
            secondary_image: {
                codename: 'secondary_image',
                id: '0603903f-5896-4b3e-936a-437435ee11a9',
                externalId: undefined,
                name: 'Secondary Image',
                required: false,
                type: 'asset'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '0b69a435-3a03-4421-a36f-34722370d18a',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Image Block  Split Content
     */
    image_block_split_content: {
        codename: 'image_block_split_content',
        id: '06dbc339-4638-40ea-be70-0f3a9728b64e',
        externalId: undefined,
        name: '🧩 Image Block (Split Content)',
        elements: {
            /**
             * Primary Image (asset)
             */
            primary_image: {
                codename: 'primary_image',
                id: 'c38eb1f2-07c9-4b03-9f88-7dbf9f3b9b48',
                externalId: undefined,
                name: 'Primary Image',
                required: false,
                type: 'asset'
            }
        }
    },

    /**
     *    Interior Hero
     */
    _interior_hero: {
        codename: '_interior_hero',
        id: '26c0e2f5-2f8e-41b2-936f-3aad65ad403f',
        externalId: undefined,
        name: '🧩 Interior Hero',
        elements: {
            /**
             * Content (rich_text)
             */
            content: {
                codename: 'content',
                id: '6aa24f10-d87b-4972-98c7-f3c9ec930f47',
                externalId: undefined,
                name: 'Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Subtitle (text)
             */
            subtitle: {
                codename: 'subtitle',
                id: '2a4414c2-c443-479e-91e2-dc0b20ecf771',
                externalId: undefined,
                name: 'Subtitle',
                required: false,
                type: 'text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '561d0cf4-f64d-470a-8bd9-5bff9a67ee9c',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Logo Collection
     */
    _cohort_collection: {
        codename: '_cohort_collection',
        id: '71fbcacd-7666-4ba7-bb91-24d50ccb3629',
        externalId: undefined,
        name: '🧩 Logo Collection',
        elements: {
            /**
             * Logo Items (modular_content)
             */
            cohort_items: {
                codename: 'cohort_items',
                id: 'adfc6a46-1857-40fa-958a-023605a7cf77',
                externalId: undefined,
                name: 'Logo Items',
                required: false,
                type: 'modular_content'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: '037e8d1d-7e97-4afe-88f7-a3e85e2aa145',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Publication List
     */
    _publication_list: {
        codename: '_publication_list',
        id: 'd5639b46-f9e3-41cb-9d34-ae65f3230735',
        externalId: undefined,
        name: '🧩 Publication List',
        elements: {
            /**
             * Number of Publications to display (number)
             */
            number_of_publications_to_display: {
                codename: 'number_of_publications_to_display',
                id: 'ce89b457-264b-470c-b1c3-671e6277ee16',
                externalId: undefined,
                name: 'Number of Publications to display',
                required: false,
                type: 'number'
            }
        }
    },

    /**
     *    Split Content Block
     */
    _split_content_block: {
        codename: '_split_content_block',
        id: '3fa2afc4-fcb1-4f38-b4fa-f734d2c17e82',
        externalId: undefined,
        name: '🧩 Split Content Block',
        elements: {
            /**
             * Block Styling (multiple_choice)
             */
            block_styling: {
                codename: 'block_styling',
                id: '741b085a-d5a6-42e5-8645-59221272c4db',
                externalId: undefined,
                name: 'Block Styling',
                required: false,
                type: 'multiple_choice',
                options: {
                    n60_40: {
                        name: '60 40',
                        id: 'dbabb113-ea29-4bfd-babf-60211891a1a9',
                        codename: 'n60_40',
                        externalId: undefined
                    },
                    n40_60: {
                        name: '40 60',
                        id: 'af6a4af8-0dc4-4784-8602-6ffdda6dc862',
                        codename: 'n40_60',
                        externalId: undefined
                    },
                    n50_50: {
                        name: '50 50',
                        id: 'b2439348-2775-4552-9d5d-5ff57551ae4c',
                        codename: 'n50_50',
                        externalId: undefined
                    },
                    n70_30: {
                        name: '70 30',
                        id: '6ad50da6-e521-4cb0-b7bc-ab366dad02ff',
                        codename: 'n70_30',
                        externalId: undefined
                    },
                    n30_70: {
                        name: '30 70',
                        id: '4102efd7-2b16-4350-92b3-118c0adc121f',
                        codename: 'n30_70',
                        externalId: undefined
                    }
                }
            },

            /**
             * Left Content (rich_text)
             */
            left_content: {
                codename: 'left_content',
                id: 'b9dda9be-11f4-457f-a14b-7cb6b6c0d566',
                externalId: undefined,
                name: 'Left Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Mobile Styling (multiple_choice)
             */
            mobile_styling: {
                codename: 'mobile_styling',
                id: '2eb8271d-5f0c-476c-93d9-3d1d242f9571',
                externalId: undefined,
                name: 'Mobile Styling',
                required: false,
                type: 'multiple_choice',
                options: {
                    left_content_on_top: {
                        name: 'Left content on top',
                        id: '4ea66550-61e9-43be-90dd-d068aa517e70',
                        codename: 'left_content_on_top',
                        externalId: undefined
                    },
                    right_content_on_top: {
                        name: 'Right content on top',
                        id: '270c99a3-fac2-4984-aeea-7233201f913b',
                        codename: 'right_content_on_top',
                        externalId: undefined
                    }
                }
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Right Content (rich_text)
             */
            right_content: {
                codename: 'right_content',
                id: 'aaa58388-06f2-408f-af66-ad6303c18c62',
                externalId: undefined,
                name: 'Right Content',
                required: false,
                type: 'rich_text'
            },

            /**
             * Title (text)
             */
            title: {
                codename: 'title',
                id: 'b151b147-58b8-4eba-b7dc-46829760fa04',
                externalId: undefined,
                name: 'Title',
                required: false,
                type: 'text'
            }
        }
    },

    /**
     *    Split Image Block
     */
    _split_image_block: {
        codename: '_split_image_block',
        id: 'f085934e-b782-4be9-901c-8fa621458d3a',
        externalId: undefined,
        name: '🧩 Split Image Block',
        elements: {
            /**
             * Left Primary Logos (modular_content)
             */
            left_primary_logos: {
                codename: 'left_primary_logos',
                id: 'c2b069ad-ba27-4cfe-ac3b-6238a26d2834',
                externalId: undefined,
                name: 'Left Primary Logos',
                required: false,
                type: 'modular_content'
            },

            /**
             * Left Secondary Logos (modular_content)
             */
            left_secondary_logos: {
                codename: 'left_secondary_logos',
                id: '509a1a95-b766-4539-919d-6e355b3dcc93',
                externalId: undefined,
                name: 'Left Secondary Logos',
                required: false,
                type: 'modular_content'
            },

            /**
             * Left Title (text)
             */
            left_title: {
                codename: 'left_title',
                id: 'bcb07612-03f6-4b59-86f3-49ae730879d4',
                externalId: undefined,
                name: 'Left Title',
                required: false,
                type: 'text'
            },

            /**
             * Padding Modifications (multiple_choice)
             */
            padding_modifications__padding_modifications: {
                codename: 'padding_modifications__padding_modifications',
                id: 'c6f4920e-7d5a-44c2-beb9-c0f78be9928a',
                externalId: undefined,
                name: 'Padding Modifications',
                required: false,
                type: 'multiple_choice',
                options: {
                    no_top_padding: {
                        name: 'No Top Padding',
                        id: 'ed34e201-a356-4b63-bb8c-98e484811603',
                        codename: 'no_top_padding',
                        externalId: undefined
                    },
                    no_bottom_padding: {
                        name: 'No Bottom Padding',
                        id: '0a7a95d4-bc74-4e75-be9e-c7d98a33bb95',
                        codename: 'no_bottom_padding',
                        externalId: undefined
                    }
                },
                snippetCodename: 'padding_modifications'
            },

            /**
             * Right Primary Logos (modular_content)
             */
            right_primary_logos: {
                codename: 'right_primary_logos',
                id: 'cb641a05-a5c7-4116-8d08-4fd5f1b461be',
                externalId: undefined,
                name: 'Right Primary Logos',
                required: false,
                type: 'modular_content'
            },

            /**
             * Right Secondary Logos (modular_content)
             */
            right_secondary_logos: {
                codename: 'right_secondary_logos',
                id: '1acd2305-6bc0-4f99-8084-1516b07ed75f',
                externalId: undefined,
                name: 'Right Secondary Logos',
                required: false,
                type: 'modular_content'
            },

            /**
             * Right Title (text)
             */
            right_title: {
                codename: 'right_title',
                id: '31d47b2c-1c35-4954-8f03-0b254432c6a2',
                externalId: undefined,
                name: 'Right Title',
                required: false,
                type: 'text'
            }
        }
    }
} as const;
