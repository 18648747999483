export const truncate = (text: string, length: number) => {
  return text?.length > length ? text.substring(0, length) + '...' : text
}
export const truncateWords = (text: string, length: number) => {
  return text?.split(' ').slice(0, length).join(' ') + '...'
}
export const getFirstParagraph = (text: string) => {
  return text?.split('\n')[0]
}
export const stripHtmlTags = (html: string) => {
  return html?.replace(/<[^>]*>?/gm, '')
}

export const prettyDate = (date: string) => {
  const dateObject = new Date(date)
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }
  return dateObject.toLocaleDateString('en-US', options)
}
export const prettyDate2Digit = (date: string) => {
  const dateObject = new Date(date);
  
  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
  const year = String(dateObject.getFullYear()).slice(-2); 

  return `${month}.${day}.${year}`;
}

export const getLimitedContent = (
  readingMore: boolean,
  charCount: number | null,
  content: string | null
) => {
  if (!charCount && content) return content
  if (!charCount || !content) return ''
  if (charCount && !readingMore) {
    let result = content.slice(0, charCount)

    if (result.includes('<') || result.includes('>')) {
      const startIndex = result.lastIndexOf('<')
      const endIndex = result.lastIndexOf('>')

      const tag = result.slice(startIndex, endIndex + 1)

      result = `${result} ${tag.replace('<', '</')}`
    }

    return result
  }
  return content
}


export const formatDate = (dateString: string | null) => {
  if (!dateString) return ''
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' }
  return date.toLocaleDateString('en-US', options)
}

export const formatDateWithFormat = (dateString: string | null, format: string) => {
  if (!dateString) return ''
  const date = new Date(dateString)

  let result = format

  const d = date.getDate()
  const M = date.getMonth() + 1
  const y = date.getFullYear()

  result = result.replace('dd', d.toString().padStart(2, '0'))
  result = result.replace('MM', M.toString().padStart(2, '0'))
  result = result.replace('yy', y.toString().slice(y.toString().length - 2, y.toString().length))

  return result
}

export const formatedTimeRange = (
  initialTime: string | null,
  timezone: string | null,
  finalTime: string | null
) => {
  if (!initialTime || !timezone || !finalTime) return ''

  const startDate = new Date(initialTime)
  const endDate = new Date(finalTime)

  // Helper function to get timezone abbreviation
  const getTimeZoneAbbreviation = (date: Date, timeZone: string) => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone,
      timeZoneName: 'short'
    }
    const formatter = new Intl.DateTimeFormat('en-US', options)
    const parts = formatter.formatToParts(date)
    const timeZonePart = parts.find((part) => part.type === 'timeZoneName')
    return timeZonePart ? timeZonePart.value : ''
  }

  const timeZoneAbbreviationStart = getTimeZoneAbbreviation(startDate, timezone)
  const timeZoneAbbreviationEnd = getTimeZoneAbbreviation(endDate, timezone)

  const timeZoneAbbreviation = timeZoneAbbreviationStart || timeZoneAbbreviationEnd || 'UTC'

  const timeZoneDate = new Intl.DateTimeFormat('en-US', {
    timeZone: timezone,
    hour12: true,
    hour: 'numeric',
    minute: '2-digit'
  })

  const startTime = timeZoneDate.format(startDate)
  const endTime = timeZoneDate.format(endDate)

  const output = `${startTime} – ${endTime} ${timeZoneAbbreviation}`

  return output
}

export function escapeSingleQuotes(value: string | undefined) {
  if (!value) return '';
  return value.replace(/'/g,  "''"); // Replace single quotes with two single quotes
 }

 export function escapeColon(text: string): string {
  return text.replace(/:/g, '\\:').trim();
}

export function formatPath(text:string):string {
  let str = '';
  if (text && text.charAt(0) == '/'){
    for (let i = 1; i <text.length; i++){
      str = `${str}${text[i]}`;
    }
  }
   
  if (str) return str;

  return text;
}

export function getDateAsNumber(str?: string | null) {
  if (!str) return 0;
  const date = new Date(str);
  const result = parseInt(`${date.getFullYear()}${date.getMonth().toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`)

  return result;
}