<template>
    <div class="publication-list" v-bind:[attributes.componentId]="type.id">
        <div class="container">
            <div class="row">

                <div class="col-lg-3">
                    <div class="filter-sidebar" data-aos="fade-in">
                        <div class="sort-by">
                            <div class="filter-title" >
                                <span class="text">Sort By</span>
                            </div>
                            <div class="filter-list">
                                <div class="form-check">
                                    <input class="form-check-input" @change="onSortChange" id="newest" name="newest"
                                        v-model="sort" type="radio" :value="0">
                                    <label class="form-check-label" for="newest">Publication Date</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" @change="onSortChange" id="oldest" name="oldest"
                                        v-model="sort" type="radio" :value="1">
                                    <label class="form-check-label" for="oldest">Leading Author (A-Z)</label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" @change="onSortChange" id="a-z" name="a-z"
                                        v-model="sort" type="radio" :value="2">
                                    <label class="form-check-label" for="a-z">Organization (A-Z)</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-9">
                    <div class="content">

                        <PublicationListContainer :model="dataSource.all"
                            :increment="model?.elements?.numberOfPublicationsToDisplay?.value ?? defaultLoadValue"
                            :title="''" />

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { contentTypes, type PublicationListModel, type PublicationItemModel } from '~/models';
import type { PublicationListDataSource } from '~/types/PublicationListDataSource';

const type = contentTypes._publication_list;
const attributes = GetKontentAttributes();

const props = defineProps<{ model: PublicationListModel }>();
const defaultLoadValue = 3;
const dataSource: PublicationListDataSource = {
    all: ref<PublicationItemModel[]>([]),
    showAllCount: ref(props?.model?.elements?.numberOfPublicationsToDisplay?.value ?? 0),
}


const sort = ref<number>();

const data = await useFetch('/api/publication-list');

const result = data?.data?.value?.data?.item;

if (result) {
    setup(result);
}

const sortFunctions: { [key: number]: any } = {
    [0]: (a: PublicationItemModel, b: PublicationItemModel) => getDateAsNumber(a.elements.publicationDate.value) - getDateAsNumber(b.elements.publicationDate.value),
    [1]: (a: PublicationItemModel, b: PublicationItemModel) => a.elements.authorInformation.value > b.elements.authorInformation.value ? 1 : -1,
    [2]: (a: PublicationItemModel, b: PublicationItemModel) => a.elements.organization.value > b.elements.publicationTitle.value ? 1 : -1
}


function onSortChange() {
    if (sort.value || sort.value == 0) {
        const sortedSrc = result?.sort(sortFunctions[sort.value]);
        if (sortedSrc) {
            setup(sortedSrc);
        }
    }
}

function setup(source: PublicationItemModel[]) {
    dataSource.all.value = source;
}



</script>