<template>
  <section
    class="featured-content-block"
    :class="stylingOption"
    v-bind:[attributes.componentId]="type.id"
  >
    <div class="style-50-50">
      <div class="container">
        <div class="row">
          <div
            class="left-col col-lg-6"
            v-bind:[attributes.elementCodename]="type.elements.left_content.codename"
          >
            <RichText :value="leftItemList" />
          </div>
          <div
            class="right-col col-lg-6"
            v-bind:[attributes.elementCodename]="type.elements.right_content.codename"
          >
            <RichText :value="rightItemList" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
import { contentTypes, type FeaturedContentBlockModel } from '~/models'

const props = defineProps<{ model: FeaturedContentBlockModel }>()

const rightItemList = ref()
const leftItemList = ref()

rightItemList.value = props.model.elements.rightContent.value
leftItemList.value = props.model.elements.leftContent.value

const stylingOption = computed(() => {
  const option =
    props.model.elements.stylingOption.value.length > 0
      ? props.model.elements.stylingOption.value[0].codename
      : undefined

  switch (option) {
    case type.elements.styling_option.options.navy.codename:
      return 'navy'
    case type.elements.styling_option.options.white.codename:
      return 'white'
    default:
      return ''
  }
})

const attributes = GetKontentAttributes()
const type = contentTypes._featured_content_block
</script>
<style lang="scss" scoped>
.navy {
  background-color: #21488d;
  color: #fff;

  :deep(.call-to-action) {
    background: linear-gradient(93deg, #5d85bf 1.44%, #8e88e8 98.24%);

    &::before {
      background-color: #21488d;
    }

    &::after {
      color: #fff;
    }
  }
}
</style>
