<template>
    <section class="card-list" v-bind:[attributes.componentId]="type.id">

        <div class="no-icon" v-if="getStyleEnumByModel(model) == StyleEnum.NoIcon">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col-lg" v-for="(card) in cardList">
                        <!-- You can move Card Item into its own component if needed -->
                        <CardItem :model="pageStore.getLinkedItem<CardItemModel>(card)" />
                    </div>
                </div>
            </div>
        </div>

        <div class="left-icon" v-if="getStyleEnumByModel(model) == StyleEnum.LeftIcon">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col-lg col-md-6" v-for="(card) in cardList">
                        <CardItem :model="pageStore.getLinkedItem<CardItemModel>(card)" />
                    </div>
                </div>
            </div>
        </div>

        <div class="top-icon" v-if="getStyleEnumByModel(model) == StyleEnum.TopIcon">
            <div class="container" :class="paddingOptions(selectedPaddingOptions)">
                <div class="row">
                    <div class="col-lg col-md-6" v-for="(card) in cardList">
                        <CardItem :model="pageStore.getLinkedItem<CardItemModel>(card)" />
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script setup lang="ts">
import { type CardListModel, type CardItemModel, contentTypes } from '~/models';
import { paddingOptions } from '#imports';
const pageStore = usePageStore();

const attributes = GetKontentAttributes();
const type = contentTypes._card_list;//#region Style
enum StyleEnum {
    None,
    NoIcon,
    LeftIcon,
    TopIcon,
}

const styleMap = new Map<string, StyleEnum>([
    ['(None)', StyleEnum.None],
    ['No Icon', StyleEnum.NoIcon],
    ['Left Icon', StyleEnum.LeftIcon],
    ['Top Icon', StyleEnum.TopIcon],
]);

function getStyleEnumByModel(model: CardListModel | null): StyleEnum {

    if (!model) return StyleEnum.None;
    if (model.elements.stylingOptions && model.elements.stylingOptions.value && model.elements.stylingOptions.value.length > 0) {

        let style = model.elements.stylingOptions.value[0].name;
        return styleMap.get(style) ?? StyleEnum.None;

    }

    return StyleEnum.None;

}

//#endregion


const props = defineProps<{ model: CardListModel }>();
const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

const cardList: Ref<string[]> = ref([]);
if (props.model?.elements?.cardItems?.value) {
    cardList.value = props.model?.elements?.cardItems?.value;
}

</script>

<style lang="scss" scoped></style>