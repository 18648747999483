<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      fill="#fff"
      d="M22.262 0H1.809C0.831 0 0 0.774 0 1.727V22.272C0 23.226 0.545 24 1.523 24h20.453C22.956 24 24 23.226 24 22.272V1.727C24 0.774 23.241 0 22.262 0M9.143 9.143h3.231v1.647h0.035c0.492 -0.888 1.947 -1.79 3.746 -1.79 3.453 0 4.416 1.833 4.416 5.228v6.343h-3.429V14.854c0 -1.52 -0.607 -2.854 -2.026 -2.854 -1.724 0 -2.545 1.167 -2.545 3.082v5.489h-3.429zM3.428 20.572h3.429V9.143H3.428zm3.857 -15.429c0 1.184 -0.959 2.143 -2.143 2.143s-2.143 -0.959 -2.143 -2.143 0.959 -2.143 2.143 -2.143 2.143 0.959 2.143 2.143"
    />
  </svg>
</template>
