<template>
    <div class="publication-item" data-aos="fade-in" v-bind:[attributes.componentId]="type.id">
        <div v-bind:[attributes.elementCodename]="type.elements.publication_date.codename" class="publication-date" v-if="model.elements.publicationDate.value">
            {{ formatDate(model.elements.publicationDate.value) }}
        </div>
        <div v-bind:[attributes.elementCodename]="type.elements.publication_title.codename" class="publication-title" v-if="model.elements.publicationTitle.value">
            <h3>{{ model.elements.publicationTitle.value }}</h3>
        </div>
        <div v-bind:[attributes.elementCodename]="type.elements.publication_summary.codename" class="publication-summary" v-if="model.elements.publicationSummary.value">
            <p>{{ model.elements.publicationSummary.value }}</p>
        </div>
        <div v-bind:[attributes.elementCodename]="type.elements.author_information.codename" class="author-information">
            <p>{{ model.elements.authorInformation.value }}</p>
        </div>
        <NuxtLink v-bind:[attributes.elementCodename]="type.elements.link_to_publication.codename" class="publication-link" :to="model.elements.linkToPublication.value" :external="true">
            <span class="text">View Publication</span>
            <IconArrowRightLong class="icon" />
        </NuxtLink>
    </div>
</template>
<script lang="ts" setup>
import { contentTypes, taxonomies, type PublicationItemModel } from '~/models';
const type = contentTypes._publication_item;
import { formatDate } from '#imports';
const attributes = GetKontentAttributes();

const props = defineProps<{ model: PublicationItemModel }>();


</script>