<template>
    <div class="publication-list-container">
        <div class="header">
            <h3>{{ title }}</h3>
        </div>
        <div class="content">
            <template v-for="(item, index) in model.value">
                <PublicationItem  v-if="index < end" :model="item" :key="item.system.id"/>
            </template>
        </div>
        <div class="load-more" v-if="canLoadMore">
            <button class="btn btn-link" @click="loadMore">
                <span class="text">Load More</span>                
            </button>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { PublicationItemModel } from '~/models';

const props = defineProps<{ model: Ref<PublicationItemModel[]>, increment: number, title:string }>();

const end = ref(0);

end.value = props.increment;
const canLoadMore = computed(() => end.value <= props.model.value.length)
function loadMore(){
    end.value += props.increment;
}
</script>