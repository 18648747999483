<template>
    <section class="logo-collection" v-bind:[attributes.componentId]="type.id">
        <div class="container" :class="paddingOptions(selectedPaddingOptions)">
            <div class="row">
                <div class="col">
                    <h2 class="title" v-bind:[attributes.elementCodename]="type.elements.title.codename">
                        {{ title }}
                    </h2>
                    <div class="logo-items-container"
                        v-bind:[attributes.elementCodename]="type.elements.cohort_items.codename">
                        <div class="logo-item" v-for="logo in logos">
                            <NuxtLink v-if="logo.elements.cohortPage.value.length > 0"
                                :to="siteStore.getUrlByCodename(logo.elements.cohortPage.value[0])">
                                <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                    :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                            </NuxtLink>
                            <NuxtLink v-else :to="logo.elements.link.value">
                                <NuxtImg v-if="logo.elements.imageGreyscale.value.length > 0"
                                    :src="logo.elements.imageGreyscale.value[0].url" :alt="logo.elements.title.value" />
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { contentTypes, type CohortCollectionModel, type CohortItemModel } from '~/models';
import { paddingOptions } from '#imports';
const siteStore = useSiteStore();
const pageStore = usePageStore();
const props = defineProps<{ model: CohortCollectionModel }>();
const data = props.model.elements;
const title = ref('');
title.value = data.title.value;
const logos = ref<CohortItemModel[]>([]);
logos.value = [...data.cohortItems.value.map(codename => pageStore.getLinkedItem<CohortItemModel>(codename))];

const attributes = GetKontentAttributes();
const type = contentTypes._cohort_collection;

const selectedPaddingOptions = ref<string[]>([]);
selectedPaddingOptions.value = props.model.elements.paddingModificationsPaddingModifications.value.map((item) => item.codename);

</script>

<style lang="scss" scoped></style>